.nav-main {
  margin-right: 48px;
  @include breakpoint-down(md) {
    order: 1;
    margin-bottom: 24px;
    margin-right: 0;
    li {
      display: block;
    }
  }
  & > ul {
    display: flex;
    padding-top: 8px;
    @include breakpoint-down(md) {
      display: block;
    }
    & > li {
      padding: 0 8px;
      @include breakpoint-down(md) {
        padding: 0;
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      &:not(:last-child) {
        margin-right: 48px;
      }
    }
  }
  ul {
    margin-bottom: 0;
  }
  a {
    color: #000;
    // @include text-small;
    font-size: 13px;
    line-height: 24px;
    font-weight: 500;
    white-space: nowrap;
    border-bottom: 2px solid transparent;
    &:hover {
      border-color: $brand-primary;
      @include breakpoint-down(md) {
        border-color: transparent;
      }
    }
    @include breakpoint-down(md) {
      @include text-large;
      white-space: initial;
    }
  }
  .active {
    & > a {
      border-color: $brand-primary;
      @include breakpoint-down(md) {
        border-color: transparent;
        color: $brand-primary;
      }
    }
  }
  .dropdown {
    position: relative;
    &.is--open {
      ul {
        @include breakpoint-down(md) {
          display: block;
        }
      }
    }
    ul {
      display: none;
      padding-top: 8px;
      width: 0;
      // position: absolute;
      list-style: none;
      padding-left: 0;
      @include breakpoint-down(md) {
        position: static;
        padding: 16px 0 16px 16px;
        background: transparent;
        width: 100%;
      }
      a {
        white-space: nowrap;
        @include breakpoint-down(md) {
          color: $brand-primary;
          @include text-small;
        }
      }
      li {
        border-bottom: none;
        margin-right: 0;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
    &:hover {
      ul {
        @include breakpoint-up(lg) {
          display: block;
        }
      }
    }
  }
}
