:root {
  --plyr-color-main: $brand-primary;
}
.video-block {
  .plyr--video {
    border-radius: 16px;
  }
}
.plyr__control--overlaid {
  background: transparent;
  z-index: 3;
  &::before {
    content: "";
    background: url(../images/play.svg) no-repeat center;
    width: 80px;
    height: 80px;
    display: block;
  }
  svg {
    display: none;
  }
}
.plyr--video {
  &.is--ready {
    .plyr__controls {
      opacity: 0;
    }
    &::before {
      content: '';
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
      @include stretch;
      z-index: 2;
    }
  }
}
.section-video {
  .section-content {
    & + .section-footer {
      margin-top: 24px;
    }
  }
}
