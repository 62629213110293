
.smooth-accordion-alpine {
  &-item {
    position: relative;
    margin-bottom: 24px;
    border: 1px solid #c9daf4;
    border-radius: 6px;
    padding: 17px 24px;
  }
  &-content {
    max-height: 0;
    transition: all .7s;
    overflow: hidden;
    position: relative;
    &-wrap {
      padding-top: 24px;
    }
  }
  &-btn {
    position: relative;
    display: block;
    padding-right: 20px;
    color: #000;
    cursor: pointer;
    color: $brand-primary;
    font-weight: 600;
    @include text-large;
    // &:hover {
    //   color: inherit;
    // }
  }
  .collapsed {
    background: $brand-primary;
    .smooth-accordion-alpine-btn {
      color: #fff;
      &::before {
        transform: rotate(180deg);
      }
    }
  }
}
