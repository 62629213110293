h1 {
  @include title-1;
}
h2 {
  @include title-2;
}
h3 {
  @include title-3;
}
h4 {
  @include title-4;
}
// .text-large {
//   @include text-large;
// }
// .text-medium {
//   @include text-medium;
// }
// .text-small {
//   @include text-small;
// }
// .text-caption {
//   @include text-caption;
// }
