.section-contact {
  text-align: center;
  .link-phone {
    color: $brand-primary;
    font-family: 'Poppins';
    @include title-1;
    font-weight: 700;
    margin-bottom: 32px;
    display: block;
    @include breakpoint-down(md) {
      font-size: 32px;
      line-height: 45px;
      margin-bottom: 24px;
    }
  }
}
.section-contacts {
  .contacts-list {
    .item-title {
      @include title-4;
    }
    .item-list {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}
.main-map {
  height: 500px;
  position: relative;
  width: 100%;
  @include breakpoint-down(md) {
    margin-bottom: 24px;
    height: 300px;
  }
  .map {
      @include stretch;
  }

}
