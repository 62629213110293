.my-col-5-3 {
  @include col-custom(4.1);
}
.my-col-3-9 {
  @include offset-custom(1.2);
  @include col-custom(3.9);
}
.my-col-2-8 {
  @include col-custom(2.8);
}
[x-cloak] {
  display: none !important;
}
.page-title {
  @include title-1;
  font-weight: 600;
  margin-bottom: 48px;
}
.page-link {
  list-style: none;
  padding-left: 0;
  margin-bottom: 48px;
  li {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 48px;
    }
    a {
      color: $brand-secondary;
      @include title-1;
    }
  }
  .active {
    a {
      color: $brand-primary;
    }
  }
}
.padding-top-header {
  padding-top: 100px;
  @include breakpoint-down(md) {
    padding-top: 56px;
  }
}
