.custom-checkbox {
  position: relative;
  display: inline-block;
  margin: 0;
  width: 50px;
  height: 26px;
  vertical-align: middle;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .custom-checkbox-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
    background-color: #262c32;
    border-radius: 34px;
    &::before {
      position: absolute;
      content: '';
      height: 18px;
      width: 18px;
      left: 4px;
      bottom: 4px;
      transition: 0.4s;
      background-color: #f15a22;
      border-radius: 50%;
    }
  }
  .custom-checkbox-status {
    position: absolute;
    top: 50%;
    right: calc(100% - -8px);
    transform: translateY(-50%);
    &::before {
      content: attr(data-inactive);
      white-space: nowrap;
      font-weight: 600;
    }
  }
  input:checked + .custom-checkbox-slider {
    background-color: #f15a22;
  }
  input:checked + .custom-checkbox-slider::before {
    background-color: #FFF;
    transform: translateX(24px);
  }

  input:checked~.custom-checkbox-status::before {
          content: attr(data-active)!important;
          font-weight: 600;
        }
}

.checkbox-reverse {
  .custom-checkbox {
    .custom-checkbox-status {
      left: calc(100% - -8px)!important;
    }
  }
}
