.section-description {
  &-bg {
    background-color: $brand-primary;
    padding: 124px 0 102px;
    color: #fff;
    @include breakpoint-down(md) {
      padding: 74px 0 52px;
    }
    .section-head {
      margin-bottom: 0;
    }
    .section-title {
      font-family: 'Poppins';
      @include title-2;
      font-weight: bold;
    }
  }
  .btn {
    margin-top: 16px;
  }
}
