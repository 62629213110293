// Grid
@mixin create-container() {
	width: 100%;
	padding-right: ($gutter-width / 2);
	padding-left: ($gutter-width / 2);
	margin-right: auto;
	margin-left: auto;
}

@mixin create-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
	@each $breakpoint, $container-max-width in $max-widths {
	  @include breakpoint-up($breakpoint, $breakpoints) {
		max-width: $container-max-width;
	  }
	}
}

@import 'grid-flex';
