// переменные проекта

$font-family-base: "Poppins", Helvetica, Arial, sans-serif !default;

$path-fonts:    "../fonts"; // путь к директории со шрифтами

$font-size-base: 16px !default;
$line-height-base: 24px !default;

$footer-height: 100px;

// переменные Цвета
// *************************************************************************
$blue: blue;
$green: green;
$orange: #F15A22;
$red: red;
$pink: pink;
$cyan: #4bb9aa;
$purple: purple;
$yellow: #facf1a;
$gray: gray;
$black: #262C32;

$brand-primary: $orange;
$brand-secondary: darken($orange,10);

// --------------------------------------------------
$body-bg: white !default;
$text-color: $black !default;

$link-color: $brand-primary !default;
$link-hover-color: $brand-secondary !default;

// --------------------------------------------------
$input-border: #CCCCCC !default;
$input-bg:  transparent !default;
$input-bg-disabled: $gray !default;
$input-color: $black !default;
$input-border-focus: $brand-primary !default;
$input-color-placeholder: $gray !default;

@function t($template){
  @return #{'[data-template="#{$template}"]'};
}

$template-main: t("home");
$template-main: t("contacts");
