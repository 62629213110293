.promo-carousel {
  margin-bottom: 72px;
  background: $brand-primary;
  padding-bottom: ratio(455px, 1425px);
  position: relative;
  @include breakpoint-down(md) {
    padding-bottom: ratio(400px, 375px);
    margin-bottom: 56px;
  }
  .swiper-container {
    @include stretch;
    // height: 455px;
    // @include breakpoint-down(md) {
    //   height: 400px;
    // }
  }
  .img {
    @include stretch;
    background-position: center;
    background-size: cover;
    z-index: -1;
  }
  .item-carousel {
    padding-top: 16px;
    height: 100%;
    display: flex;
    padding-bottom: 24px;
    align-items: flex-end;
    @include breakpoint-down(md) {
      padding-bottom: 16px;
    }
    // &:not(.item-carousel-top) {
    // }
    &.item-carousel-top {
      .title {
        p {
          strong {
            padding: 10px 16px;
            background: rgba($color: #000000, $alpha: .5);
          }
          & + p {
            strong {
              padding-top: 0;
            }
          }
        }
      }
    }
    // @include breakpoint-down(md) {
    //   padding-top: 116px;
    // }
  }
  .item-content-wrap {
    margin-bottom: 18px;
  }
  .title {
    text-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    color: #ffffff;
    font-family: 'Poppins';
    p {
      margin-bottom: 0;
      font-weight: bold;
      font-size: 32px;
      line-height: 48px;
      @include breakpoint-down(md) {
        font-size: 24px;
        line-height: 36px;
      }
      strong {
        display: inline-block;
      }
    }
  }
  .swiper-pagination-controls {
    position: absolute;
    bottom: 24px;
    right: 0;
    left: 0;
    z-index: 10;
  }
  .swiper-pagination {
    background-color: #000;
    padding: 2px;
    &-bullet {
      background-color: #000;
      &-active {
        background-color: $brand-primary;
      }
    }
  }
  & + .section-really,
  & + .section-banner,
  & + .section-description-bg,
  & + .section-banner-right,
  & + .section-video,
  & + .section-check-Service {
    margin-top: -72px;
    @include breakpoint-down(md) {
      margin-top: -32px;
    }
  }
}
