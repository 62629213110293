.section-really {
  background: $brand-secondary;
  padding: 72px 0;
  @include breakpoint-down(md) {
    padding: 32px 0;
  }
  .item-title {
    @include title-3;
    font-weight: bold;
    margin-bottom: 42px;
    @include breakpoint-down(md) {
      margin-bottom: 24px;
      text-align: center;
    }
  }
  .item-desc {
    li {
      @include text-small;
      font-family: 'Poppins';
      &::before {
        background: #000;
        top: 6px;
      }
    }
  }
  .media {
    img {
      display: block;
      max-width: 100%;
      height: auto;
      @include breakpoint-down(md) {
        max-width: 50%;
        margin: 0 auto;
      }
    }
  }
}
