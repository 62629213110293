.section-carousel-column {
  .swiper-slide {
    height: auto;
  }
  .row {
    margin-bottom: -24px;
    justify-content: center;
  }
  .col-lg-4 {
    margin-bottom: 24px;
  }
  .section-content {
    position: relative;
  }
  .section-title {
    text-align: center;
  }
  .section-carousel-controls {
    display: none;
    @include breakpoint-down(md) {
      display: flex;
    }
  }
  .item-carousel {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    height: 100%;
    text-align: center;
    max-width: 275px;
    margin: 0 auto;
    @include breakpoint-down(md) {
      max-width: 100%;
    }
  }
  .item-media {
    position: relative;
    width: 276px;
    height: 276px;
    margin: 0 auto 24px;
  }
  .img {
    @include stretch;
    background-position: center;
    background-size: cover;
    z-index: -1;
    border-radius: 50%;
  }
  .item-title {
    font-family: 'Poppins';
    @include title-4;
    font-weight: 700;
    margin-bottom: 6px;
  }
  .item-desc {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    @include text-small;
    & + .item-footer {
      padding-top: 30px;
    }
  }
}
