@font-face {
  font-family: 'iconfont';
  src: 	url('#{$path-fonts}/iconfont.ttf') format('truetype'),
    url('#{$path-fonts}/iconfont.woff') format('woff'),
    url('#{$path-fonts}/iconfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checked:before {
  content: "\e900";
}
.icon-chevron-left:before {
  content: "\e901";
}
.icon-chevron-right:before {
  content: "\e902";
}
.icon-close:before {
  content: "\e903";
}
.icon-fb:before {
  content: "\e904";
}
.icon-in:before {
  content: "\e905";
}
.icon-ins:before {
  content: "\e906";
}
.icon-lightning:before {
  content: "\e907";
}
.icon-mail:before {
  content: "\e908";
}
.icon-message:before {
  content: "\e909";
}
.icon-phone:before {
  content: "\e90a";
}
.icon-phone-bg:before {
  content: "\e90b";
}
.icon-phone-talk:before {
  content: "\e90c";
}
.icon-search:before {
  content: "\e90d";
}
.icon-tw:before {
  content: "\e90e";
}
.icon-yt:before {
  content: "\e90f";
}
