.section-step {
  text-align: center;
  .item-list {
    margin-bottom: 24px;
  }
  .item-num {
    border: 2px solid $brand-primary;
    color: $brand-primary;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include title-3;
    width: 72px;
    height: 72px;
    margin-bottom: 24px;
  }
  .item-title {
    font-weight: bold;
    margin-bottom: 16px;
    @include breakpoint-down(md) {
      font-size: 20px;
    }
    span {
      text-decoration: underline;
    }
  }
  .item-desc {
    color: #999;
    @include text-small;
    span {
      text-decoration: underline;
    }
  }
  .section-content {
    margin-bottom: 24px;
  }
  .section-footer {
    &-desc {
      margin-bottom: 24px;
    }
  }
}
