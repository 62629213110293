.section {
  // padding: 40px 0;
  // @include breakpoint-down(md) {
  //   padding: 32px 0;
  // }
  margin-bottom: 72px;
  @include breakpoint-down(md) {
    margin-bottom: 56px;
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.section-center {
    text-align: center;
  }
  .section-head {
    margin-bottom: 32px;
    @include breakpoint-down(md) {
      margin-bottom: 24px;
    }
  }
  .section-title {
    font-family: 'Poppins';
    @include title-3;
    font-weight: 700;
    text-align: center;
    @include breakpoint-down(md) {
      font-size: 24px;
      line-height: 36px;
    }
  }
  .section-desc {
    padding-top: 24px;
    @include text-small;
    text-align: center;
    @include breakpoint-down(md) {
      padding-top: 16px;
    }
    // font-weight: 700;
  }
  .section-desc-large {
    padding-top: 32px;
    @include title-4;
    font-weight: 700;
    @include breakpoint-down(md) {
      padding-top: 0;
    }
  }
  &-footer {
    .section-desc {
      padding-top: 0;
      & + .section-footer-link {
        margin-top: 24px;
      }
    }
  }
  ul {
    list-style: none;
    padding-left: 16px;
    li {
      position: relative;
      line-height: 32px;
      &::before {
        content: '';
        width: 6px;
        height: 6px;
        background: $brand-primary;
        left: -14px;
        top: 14px;
        border-radius: 50%;
        position: absolute;
      }
    }
  }
}

.section-work {
  .item-work {
    @include col-custom(2.4);
    @include breakpoint-down(md) {
      width: 100%;
      margin-bottom: 24px;
    }
  }
  .section-desc-sub {
    padding-top: 40px;
  }
  .item-work-wrap {
    border-radius: 4px;
    background-color: $brand-primary;
    padding: 20px;
    height: 100%;
    .item-media {
     margin-bottom: 24px;
    }
    .item-desc {
      font-weight: 600;
    }
  }
}
.section-common {
  @include text-small;
  &.bg-type-1 {
    padding: 24px 0;
  }
  [class^="col-lg-"] {
    &:first-child {
      @include breakpoint-down(md) {
        order: 1;
      }
    }
    &:last-child {
      @include breakpoint-down(md) {
        order: 0;
      }
    }
  }
  &-switch {
    [class^="col-lg-"] {
      &:first-child {
        order: 1;
      }
      &:last-child {
        order: 0;
      }
    }
    .media {
      margin-left: 0 !important;
      &::before {
        left: 8px;
        right: initial;
      }
    }
  }
  p,
  ul {
    &:last-child {
      margin-bottom: 0;
    }
  }
  .title {
    font-family: 'Poppins';
    @include title-3;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .desc {
    margin-bottom: 24px;
    @include breakpoint-down(md) {
      margin-bottom: 12px;
    }
  }
  .media {
    position: relative;
    max-width: 564px;
    margin-left: auto;
    border-radius: 16px;
    &::before {
      content: '';
      position: absolute;
      top: 8px;
      right: 8px;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: inherit;
      border-radius: 16px;
      @include breakpoint-down(md) {
        left: 8px;
        right: initial;
      }
    }
    img {
      border-radius: 16px;
    }
    @include breakpoint-down(md) {
      margin-bottom: 24px;
    }
  }
}



.section-video {
  // background: $brand-secondary;
  // padding: 72px 0;
  // @include breakpoint-down(md) {
  //   padding: 32px 0;
  // }
  .section-title {
    @include title-2;
    font-weight: 700;
    text-align: center;
  }
  & + .section-banner-right {
    margin-top: -72px;
    @include breakpoint-down(md) {
      margin-top: -32px;
    }
  }
}
.grid-video-list {
  display: flex;
	justify-content: center;
	margin-left: -8px;
	margin-right: -8px;
  @include breakpoint-down(md) {
    flex-direction: column;
  }
}
.block-video {
  &-item {
    padding: 8px;
    width: 100%;
  }
  &-item-title {
    margin-bottom: 16px;
    @include text-large;
    font-weight: bold;
  }
  &-wrap {
    position: relative;
    padding-bottom: ratio(466px, 830px);
  }
  .lazyframe {
		@include stretch;
		width: 100%;
		height: 100%;
	}
}

.section-check-Service {
  text-align: center;
  padding-top: 0;
  .section-content {
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    background-color: #f6f6f6;
    padding: 32px 16px;
  }
  img {
    margin-bottom: 24px;
  }
  p {
    @include text-small;
  }
  .section-head {
    @include breakpoint-up(md) {
      margin-bottom: 40px;
    }
  }
  .title {
    font-family: 'Poppins';
    @include title-3;
    font-weight: 700;
    margin-bottom: 24px;
  }
  .btn {
    margin-top: 24px;
  }
}
.section-faq {
  padding: 56px 0;
  margin-bottom: 0;
  margin-top: -72px;
  @include breakpoint-down(md) {
    margin-top: -32px;
  }
  .section-head {
    margin-bottom: 16px;
  }
  .section-title {
    @include title-3;
  }
}
.affix-anchor {
  margin-top: -72px;
  position: relative;
  z-index: -1;
  transform: translateY(-100px);
  @include breakpoint-down(md) {
    transform: translateY(-56px);
    margin-top: -56px;
  }
}
.section-link {
  padding: 0 0 64px;
  position: relative;
  .nav.affix, /* the fixed position and a width are required */
  .nav.affix-bottom { position: fixed !important; width: 100%; }
  .nav.affix {
    top: 70px;
    @include breakpoint-down(md) {
      top: 56px;
    }
   }  /* affixedTop position */
  .nav.affix-bottom { top: auto; bottom: 100px; }  /* affixedBottom position */
  .nav {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    right: 0;
    background: $brand-primary;
    height: 64px;
  }
  ul {
    padding-left: 0;
    display: flex;
    justify-content: center;
    @include breakpoint-down(md) {
      overflow-x: auto;
      justify-content: initial;
    }
    li {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;
      font-weight: bold;
      &:not(:last-child) {
        margin-right: 12px;
      }
      &::before {
        display: none;
      }
    }
    a {
      color: #fff;
      white-space: nowrap;
      &:hover {
        color: $brand-secondary;
      }
    }
  }
}

.bg-type-1 {
  background: rgba($color: $brand-primary, $alpha: .2);
}
.section-404 {
  padding-top: 190px;
  margin-bottom: 100px;
  @include breakpoint-down(md) {
    padding-top: 120px;
    margin-bottom: 32px;
  }
  .section-desc {
    @include title-3;
  }
}
ul.custom-list {
  p {
    display: inline-block;
    margin-bottom: 0;
    position: relative;
    @extend .icon-checked;
    &::before {
      font-family: 'iconfont';
      background-color: transparent;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      font-size: 16px;
      color: $brand-primary;
      right: calc(100% + 8px);
      top: 4px;
    }
  }
  li {
    color: #000;
    font-size: 14px;
    &::before {
      display: none;
    }
    span,
    a {
      text-decoration: underline;
      color: #000;
    }
  }
}
.section-only-step {
  .only-step-list {
    display: flex;
    justify-content: center;
    margin: 0 -12px -24px;
    @include breakpoint-down(md) {
      flex-wrap: wrap;
    }
  }
  .item-head {
    padding: 0 24px;
    margin-bottom: 40px;
    @include breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }
  .item-media {
    position: relative;
    padding-bottom: ratio(336px, 569px);
    // margin: 0 auto 40px;
  }
  .img {
    @include stretch;
    background-position: center;
    background-size: cover;
  }
  .item-list {
    margin-bottom: 24px;
    padding: 0 12px;
    max-width: 617px;
  }
  .item-title {
    @include text-large;
    margin-bottom: 16px;
    font-weight: bold;
  }
  .item-desc {
    @include text-small;
  }
  .item-link {
    margin-top: 40px;
    @include breakpoint-down(md) {
      margin-top: 16px;
    }
  }
}
