.footer {
  background: $black;
  color: #fff;
  padding: 34px 0 24px;
  a {
    color: #fff;
  }
  &-nav {
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    margin-left: 62px;
    @include breakpoint-down(md) {
      margin-left: 0px;
      margin-top: 16px
    }
    ul {
      @include list-unstyled;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      @include breakpoint-down(md) {
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -8px;
      }
    }
    li {
      @include breakpoint-down(md) {
        padding: 8px;
      }
      &:not(:last-child) {
        margin-right: 48px;
        @include breakpoint-down(md) {
          margin-right: 0px;
        }
      }
    }
  }
  &-sup {
    display: flex;
    align-items: baseline;
    margin-bottom: 32px;
    @include breakpoint-down(md) {
      flex-direction: column;
    }
  }
  &-title {
    font-weight: 600;
    margin-bottom: 7px;
  }
  &-desc {
    @include text-caption;
    color: rgba(#fff, .8);
  }
  &-about {
    margin-bottom: 16px;
  }
  &-contacts {
    display: flex;
    justify-content: space-between
  }
  &-list-link {
    @include list-unstyled;
    font-weight: bold;
    @include text-small;
    margin-bottom: 0;
    .icon {
      font-size: 24px;
      margin-right: 8px;
    }
    li {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
    a {
      display: flex;
      align-items: center;
      color: rgba(#fff, .8);
    }
  }
  .social-list {
    @include list-unstyled;
    margin-bottom: 0;
    display: flex;
    font-size: 24px;
    @include breakpoint-down(md) {
      margin-bottom: 16px;
    }
    li {
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
  &-bottom {
    margin-top: 31px;
    color: rgba(255, 255, 255, 0.56);
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.444444px;
    p,
    ul {
      &:last-child {
        margin-bottom: 0;
      }
    }
    ul {
      list-style: none;
      padding-left: 0;
    }
    li {
      display: inline-block;
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
    a {
      color: inherit;
    }
  }
}
