.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 12;
  transition: background .4s ease-in-out;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  // &.not-scrolled {
  //   background: rgba(#fff, .9);
  //   box-shadow: none;
  // }
  &-in {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 31px;
    @include breakpoint-down(md) {
      align-items: center;
      padding: 8px 0;
    }
  }
  .logo {
    margin-right: 24px;
    padding-top: 3px;
    @include breakpoint-down(md) {
      // padding-top: 0;
    margin-right: calc(100% - 233px);
    }
  }
  .link-phone {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    margin-right: 2px;
    white-space: nowrap;
    @include breakpoint-up(md) {
      margin-right: 24px;
      display: none;
    }
    .icon {
      font-size: 24px;
      @include breakpoint-up(md) {
        margin-right: 8px;
      }
    }
    &:hover {
      color: $brand-primary;
      span {
        & + span {
          color: $brand-primary;
        }
      }
    }
    span {
      color: $brand-primary;
      font-size: 14px;
      & + span {
        color: #000;
        margin-left: 4px;
      }
    }
    &-mobile {
      opacity: 1;
      transition: opacity .4s ease-in-out;
      @include breakpoint-up(md) {
        display: none;
      }
    }
  }
  &-sidebar {
    width: 100%;
    @include breakpoint-down(md) {
      position: fixed;
      top: 56px;
      right: 0;
      max-width: 375px;
      background: #fff;
      padding: 32px 8px 24px;
      height: calc(100vh - 56px);
      transition: transform .4s ease;
      transform: translateX(100%);
    }
  }
  &.sidebar-opened {
    background: #fff;
    .header-sidebar {
      transform: translateX(0);
    }
    .link-phone-mobile {
      opacity: 0;
    }
  }
  &-sidebar-wrap {
    display: flex;
    justify-content: flex-end;
    @include breakpoint-down(md) {
     flex-direction: column;
     align-items: initial;
    }
    .sidebar-head {
      // width: 100%;
      display: flex;
      justify-content: space-between;
      @include breakpoint-down(md) {
       flex-direction: column;
       align-items: initial;
       order: 1;
      }
    }
  }
  .btns-group {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 8px;
    @include breakpoint-down(md) {
      margin-left: 0;
      justify-content: space-between;
      margin-bottom: 24px;
    }
    .btn {
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}
.burger--icon {
  @include burger(18px, 2px, 3px, #000);
  pointer-events: none;
}
.btn--burger {
  width: 40px;
  height: 24px;
  display: none;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: 20;
  @include breakpoint-down(md) {
    display: inline-flex;
  }
  .sidebar-opened & {
    .burger--icon {
      @include burger-to-cross;
      &::before,
      &::after {
        background-color: #000;
      }
    }
  }
}
.sidebar {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  background: $brand-primary;
  z-index: 11;
  @include breakpoint-down(md) {
    top: 56px;
  }
  ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include breakpoint-down(md) {
      overflow-x: auto;
      justify-content: initial;
    }
  }
  li {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    font-weight: bold;
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
  a {
    color: #fff;
    white-space: nowrap;
    &:hover {
      color: $brand-secondary;
    }
  }
}
