.section-circle-column {
  .section-title {
    max-width: 617px;
    margin: 0 auto;
  }
  .row {
    justify-content: center;
  }
  .item-list {
    margin-bottom: 40px;
  }
  .item-title {
    @include text-large;
    font-weight: bold;
  }
  .item-media {
    position: relative;
    width: 216px;
    height: 216px;
    border-radius: 50%;
    margin: 0 auto 16px;
    .img {
      @include stretch;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
    }
  }
}
