.news {
  margin-bottom: 100px;
  @include breakpoint-down(md) {
    padding-top: 24px;
    margin-bottom: 32px;
  }
  &-list-wrap {
    @include create-row();
  }
  .item-news {
    @include col-custom(4);
    @include breakpoint-down(md) {
      width: 50%;
    }
    @include breakpoint-down(sm) {
      width: 100%;
    }
  }
  &-inner {
    padding-top: 50px;
    margin-bottom: 100px;
    @include breakpoint-down(md) {
      padding-top: 24px;
      margin-bottom: 32px;
    }
    img {
      margin-bottom: 24px;
    }
    .block-video {
      margin-bottom: 24px;
    }
    .news-title {
      @include title-3;
      margin-bottom: 8px;
    }
    .news-date {
      @include text-large;
      margin-bottom: 24px;
      color: $gray;
    }
    .news-list-title {
      @include title-4;
      margin-bottom: 16px;
    }
    .item-news {
      @include col-custom(12);
    }
    [class^="col-lg-"] {
      @include breakpoint-down(md) {
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
}
.carousel-banner {
  position: relative;
}
.item-news {
  margin-bottom: 24px;
  &-wrap {
    text-align: center;
    border-radius: 4px;
    background-color: #f6f6f6;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    color: #000;
    &:hover {
      color: $brand-primary;
    }
  }
  .item-content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
  .ratio {
    padding-bottom: ratio(204px, 308px);
    border-radius: 3px 3px 0 0;
  }
  .img {
    background-size: cover;
    background-position: center;
  }
  .item-title {
    display: flex;
    flex: 1 0 auto;
    font-family: 'Poppins';
    @include title-4;
    font-weight: 700;
    margin-bottom: 6px;
  }
  .item-meta {
    color: $brand-primary;
    @include text-small;
    font-weight: 700;
    text-transform: uppercase;
  }
  .item-content {
    padding: 13px;
  }
  .item-desc {
    margin-bottom: 24px;
  }
}
