.section-countertop-card {
  .list-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -24px -24px;
    @include breakpoint-down(md) {
      margin: 0 -8px -19px;
    }
  }
  .item-media {
    padding: 0 24px 24px;
    @include breakpoint-down(md) {
      padding: 0 8px 0;
      margin-bottom: 19px;
    }
    img {
      @include breakpoint-down(md) {
        max-width: 57px;
        margin: 0 auto;
      }
    }
  }
  .section-desc {
    color: rgba(0, 0, 0, 0.4);
  }
}
