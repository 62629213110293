.section-hightlights {
  padding-bottom: 0;
  @include breakpoint-down(md) {
    padding-bottom: 32px;
    text-align: center;
  }
  .item-list {
    margin-bottom: 95px;
    font-family: "Poppins";
    @include breakpoint-down(md) {
      margin-bottom: 0;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
  .item-sum {
    @include title-1 ;
    color: $brand-secondary;
    font-weight: 500;
    span {
      @include title-4;
      font-weight: normal;
    }
  }
  .item-desc {
    color: $brand-primary;
    @include title-4;
    font-weight: 700;
  }
}
