.promo-index {
  background: #F6F6F6;
  padding-top: 100px;
  padding-bottom: 27px;
  margin-bottom: 72px;
  &-content {
    padding-top: 93px;
    @include breakpoint-down(md) {
      padding-top: 16px;
    }
  }
  .media {
    padding-top: 50px;
    @include breakpoint-down(md) {
      padding-top: 0;
      margin: 0 auto;
      max-width: 270px;
    }
  }
  .title {
    @include title-3;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .desc {
    font-weight: bold;
    margin-bottom: 24px;
  }
}
