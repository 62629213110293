.modal {
	position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
	outline: 0;
  background: rgba(0,0,0,.5);
}
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
}

.modal-backdrop {
	&.fade {
		opacity: 0;
	}
	&.in {
		opacity: 0.5;
	}
}

.modal-dialog {
	max-width: 944px;
	margin: 30px auto;
	min-height: calc(100% - (25px * 2));
	display: flex;
	align-items: center;
}

.modal-content {
	position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  .close {
    position: absolute;
    top: 32px;
    right: 25px;
    font-size: 21px;
    z-index: 2;
  }
}

.modal {
	&.fade {
		.modal-dialog {
			transform: translate(0,-25%);
			transition: transform .3s ease-out,-webkit-transform .3s ease-out,-o-transform .3s ease-out;
		}
	}
	&.in {
		.modal-dialog {
			transform: translate(0,0);
		}
	}
}
.modal-show {
  .item-title {
    margin-bottom: 16px;
    @include title-2;
  }
  .close {
    position: static;
    color: #000;
  }
  .item-desc {
    @include breakpoint-down(md) {
      @include text-small;
    }
  }
}
