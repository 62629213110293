html,
body,
.wrapper {
	height: 100%;
  scroll-behavior: smooth;
}

body {
	color: $text-color;
	background-color: $body-bg;
	max-width: 100%;
}

.wrapper-in { // wrapper
	position: relative;
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}

.content {
	overflow: hidden;
	flex: 1;
}
