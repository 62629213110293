.item-pos {
  margin-bottom: 24px;
  @include breakpoint-down(md) {
    margin-bottom: 0px;
    &:not(:last-child) {
      margin-bottom: 40px;

    }
  }
  &-wrap {
    &:hover {
      .item-media {
        &::before {
          background-color: $brand-primary;
        }
      }
    }
  }
  img {
    width: 246px;
    height: 246px;
    margin: 0 auto;
    @include breakpoint-down(md) {
      width: 320px;
      height: 320px;
    }
  }
  .item-media {
    width: 246px;
    height: 246px;
    margin: 0 auto 18px;
    position: relative;
    display: block;
    @include breakpoint-down(md) {
      margin-bottom: 8px;
      width: 320px;
      height: 320px;
    }
    &::before {
      content: '';
      border-radius: 50%;
      position: absolute;
      top: 22px;
      left: 22px;
      right: 22px;
      bottom: 22px;
      background: #F6F6F6;
      transition: background .4s linear;
      z-index: -1;
    }
  }
  .item-title {
    margin-bottom: 16px;
    @include text-large;
    font-weight: bold;
  }
  .item-desc {
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 24px;
    @include text-small;
    @include breakpoint-down(md) {
      margin-bottom: 12px;
    }
  }
  ul {
    // display: flex;
    // flex-direction: column;
    margin-bottom: 24px;
    @include breakpoint-down(md) {
      margin-bottom: 16px;
    }
    p {
      display: inline-block;
      margin-bottom: 0;
      position: relative;
      @extend .icon-checked;
      &::before {
        font-family: 'iconfont';
        background-color: transparent;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        font-size: 16px;
        color: $brand-primary;
        right: calc(100% + 8px);
        top: 6px;
      }
    }
    li {
      color: #000;
      font-size: 14px;
      &::before {
        display: none;
      }
      span,
      a {
        text-decoration: underline;
        color: #000;
      }
    }
  }
}
.section-pos-market {
  .content-title {
    margin-bottom: 32px;
  }
  .row {
    margin-bottom: -24px;
    & + .content-title {
      padding-top: 32px;
      // @include breakpoint-down(md) {
      //   padding-top: 32px;
      // }
    }
  }
  .section-head {
    margin-bottom: 24px;
  }
}
