
@mixin extra-large {
  font-size: 120px;
  line-height: 104px;
  @include breakpoint-down(md) {
    font-size: 54px;
    line-height: 60px;
  }
}

@mixin title-1 {
  font-size: 88px;
  line-height: 88px;
  @include breakpoint-down(md) {
    font-size: 48px;
    line-height: 60px;
  }
}
@mixin title-2 {
  font-size: 48px;
  line-height: 72px;
  @include breakpoint-down(md) {
    font-size: 32px;
    line-height: 45px;
  }
}
@mixin title-3 {
  font-size: 32px;
  line-height: 48px;
  @include breakpoint-down(md) {
    font-size: 24px;
    line-height: 36px;
  }

}
@mixin title-4 {
  font-size: 24px;
  line-height: 36px;
  @include breakpoint-down(md) {
    font-size: 20px;
    line-height: 24px;
  }
}

@mixin text-large {
  font-size: 20px;
  line-height: 24px;
  @include breakpoint-down(md) {
    font-size: 18px;
  }
}

@mixin text-normal {
  font-size: 16px;
  line-height: 24px;
}

@mixin text-small {
  font-size: 14px;
  line-height: 24px;
}
@mixin text-caption {
  font-size: 12px;
  line-height: 16px;
}
