.section-column {
  &.section-column-defoult {
    .row {
      margin: (-$gutter-width / 2);
    }
    .item-column {
      padding: ($gutter-width / 2);
    }
    .section-title {
      text-align: center;
    }
  }
  .row {
    justify-content: center;
  }
  .item-column {
    text-align: center;
    margin-bottom: 24px;
    @include breakpoint-down(md) {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-wrap {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      height: 100%;
    }
  }
  .item-media {
    margin-bottom: 24px;
    min-height: 48px;
    @include breakpoint-down(md) {
      margin-bottom: 12px;
    }
    img {
      max-height: 48px;
    }
  }
  .item-title {
    font-family: "Poppins";
    font-weight: 700;
    margin-bottom: 5px;
  }
  .item-desc {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    @include text-small;
    & + .item-footer {
      padding-top: 24px;
    }
  }
}
